import * as React from 'react'
import gradientImg from '../images/gradient.png'

const imgStyles = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: `url(${gradientImg})`,
  mixBlendMode: 'lighten',
  opacity: 0.3,
  borderRadius: 8
}

const gradientStyles = {
  position: 'relative',
  background: 'linear-gradient(91.73deg, rgba(0, 224, 255, 0.4) 1.28%, rgba(189, 0, 255, 0.4) 100%), linear-gradient(268.27deg, rgba(171, 94, 240, 0.3) 0%, rgba(109, 145, 238, 0.3) 100%), #6732FF',
  borderRadius: 8
}

const GradientBox = (props) => (
  <div style={gradientStyles} {...props}>
    <div style={imgStyles}></div>
    {props.children}
  </div>
)

export default GradientBox
