import * as React from 'react'
import { useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import facepaint from 'facepaint'
import GradientBox from './GradientBox'

const mq = facepaint([
  '@media(min-width: 600px)',
])

const StyledButton = styled.a`
  display: inline-block;
  color: ${props => props.theme.colors.buttonText};
  cursor: pointer;

  font-style: normal;
  font-weight: 500;

  font-size: 1.5rem;
  line-height: 3.4rem;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;
  text-decoration: none;

  width: 100%;

  &:hover {
    opacity: 0.9;
  }
`

const boxStyles = mq ({
  padding: '10px 45px 10px',
  width: '100%'
})

const Button = (props) => {
  const theme = useTheme()

  return (
    <StyledButton theme={theme} role="button" {...props}>
      <GradientBox css={boxStyles}>
        {props.children}
      </GradientBox>
    </StyledButton>
  )
}

export default Button
