import React, { useState } from 'react'
import { Widget } from '@typeform/embed-react'
import facepaint from 'facepaint'
import { isAndroid, isIOS } from 'react-device-detect'

import Layout from '../../components/layouts/landing'
import GradientButton from '../../components/GradientButton'
import Heading from '../../components/Heading'
import Text from '../../components/Text'
import Icon from '../../components/Icon'
import IconImage from '../../images/icon.svg'

const mq = facepaint([
  '@media(min-width: 600px)'
])

const wrapperStyles = mq({
  maxWidth: ['auto', 520]
})

const IndexPage = ({ id, location }) => {
  const [formOpen, setFormOpen] = useState(false)
  let firstName = null

  if (location?.search) {
    const params = new URLSearchParams(location.search)
    const paramVal = params.get('n')

    if (paramVal) {
      try {
        firstName = atob(paramVal)
      } catch { }
    }
  }

  return (
    <Layout title="wrkvbs">
      <div css={{ width: '100%', textAlign: 'center' }}>
      <Heading css={{ marginTop: 35 }}>
            {!isIOS && `Thanks for trying to help ${firstName} complete their profile on WRKVBS.`}
            {isIOS && `WRKVBS is where top professionals support each other in growing their career`}
          </Heading>
        <Icon css={{ margin: '0 auto' }}>
          <IconImage />
        </Icon>
      </div>
      {
        !firstName && <Heading css={{ marginTop: 35 }}>
          Invalid request.
        </Heading>
      }
      {firstName && !formOpen &&
        <div css={wrapperStyles}>
          <br />
          <Text>
            {isIOS && `Join ${firstName} in the WRKVBS beta`}
            {isAndroid && 'Unfortunately we don\'t currently have an Android app. Give us your contact information and we\'ll let you know as soon as we do.'}
            {!isIOS && !isAndroid && 'We\'re not yet supporting recording on desktop, so please open the link you received on your phone to download our iOS app, or enter your information below if you are an Android user.'}
          </Text>

          {isIOS
            ? <GradientButton css={{ marginTop: 20 }} href="https://testflight.apple.com/join/XUeUt5kZ">
              Download the App
            </GradientButton>
            : <GradientButton css={{ marginTop: 20 }} onClick={() => setFormOpen(true)}>
              Sounds Good
            </GradientButton>
          }

          <Text>
            {isIOS && 
            (<div css={{ textAlign: 'left'}}>
              <br /><b>Please note:</b><br />- To join the WRKVBS beta you will need TestFlight.<br />- If you don't have TestFlight installed on your iPhone, the link above will automatically take you to download it.<br />- It’s super easy and we can’t wait to have you join.<br />- We’re always here to help. Email us at <a href="mailto: help@wrkvbs.com">help@wrkvbs.com</a> if you get stuck.
              </div>)}
          </Text>
        </div>
      }

      <div css={{ width: '100%', display: formOpen ? 'block' : 'none' }}>
        <Widget id="IkMAv7FU" height="540" hideHeaders={true} hideFooter={true} opacity="0" />
      </div>
    </Layout>
  )
}

export default IndexPage
